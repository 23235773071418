import { myAxios } from '../utils/request'

// 设置登录验证码
const ApiSetWxLoginVerifyCode = (data = {}) => {
  return myAxios.post('/WxRobot/SetWxLoginVerifyCode', {
    // 操作编号
    soNumber: data.soNumber,
    // verifyCode
    verifyCode: data.verifyCode,
  })
}
export {
  // 设置登录验证码
  ApiSetWxLoginVerifyCode,
}
