<template>
    <div class="flex column code-box">
        <van-icon name="warning" size=".5rem" class="color-warning"/>
        <div class="code-text">安全验证</div>
        <password-input
                class="code-input"
                :class="{'code-input_verify' : isVerify}"
                :value="codeValue"
                :mask="false"
                gutter=".05rem"
                :focused="showKeyboard"
                @focus="showKeyboard = true"
        />

        <div class="mt-2 fs-12 color-warning">
            {{ isVerify ? '正在提交验证，请等待' : '请输入您的数字安全码' }}
        </div>
        <button class="mt-4 reflesh button" :disabled="(codeValue.length === 6 && isVerify) || codeValue.length !== 6"
                @click="handleVerifyCode">{{ isVerify ? '正在验证...' : '确认提交' }}
        </button>
        <number-keyboard
                maxlength="6"
                v-model="codeValue"
                :show="showKeyboard"
                @blur="showKeyboard = false"
        />
    </div>

</template>

<script>
export default {
    name: "VerifyCode"
}
</script>
<script setup>
import {Dialog, Notify, NumberKeyboard, PasswordInput} from 'vant'
import {ref, watch} from "vue";
import {ApiSetWxLoginVerifyCode} from "../apis/WxRobot";

const codeValue = ref('')
const showKeyboard = ref(false)
const isVerify = ref(false)

const props = defineProps({
    soNumber: {
        type: [Number, String],
        default: ''
    }
})
const emit = defineEmits(['verifySuccess'])
/*watch(
    () => codeValue.value,
    (val, newVal) => {
      if (val.length === 6) {
        showKeyboard.value = false
        isVerify.value = true
      }
    }
)*/
const handleVerifyCode = async () => {
    try {
        showKeyboard.value = false
        isVerify.value = true
        const res = await ApiSetWxLoginVerifyCode({
            soNumber: props.soNumber,
            verifyCode: codeValue.value,
        })
        if (res.code) {
            emit('verifySuccess')
        }
    } catch (e) {
        Notify({
            type: 'danger',
            duration: 1000,
            message: '验证失败，请重新输入！'
        })
        isVerify.value = false
        codeValue.value = ''
        console.warn()
    }
}
</script>
<style lang="scss" scoped>
.code-box {
  margin-top: 15px;
  //width: 100%;
  padding: 0 15px;

  .code-text {
    margin-top: 5px;
    font-weight: bold;
  }

  .code-input {
    margin-top: 10px;
    width: 100%;

    :deep(.van-password-input__security) {
      height: 45px;
    }
  }

  .code-input_verify {
    :deep(.van-password-input__item) {
      opacity: .5;
    }
  }
}

.reflesh {
  padding: px(6) px(35);
  font-size: px(14);
  font-weight: 500;
  color: $color-primary;
  border-radius: px(2);
  border: px(1) solid $color-primary;
}
</style>
